:local(.introText) {
  font-family: "Open Sans",arial,sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4;
  margin: 16px 0 40px;

  h1:before {
    content: "Veiviser";
    display: block;
    color: gray;
    text-transform: uppercase;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    font-weight: 300;
    font-size: 1.2rem;
  }

  h2 {
    font-family: "Open Sans",arial,sans-serif;
    font-size: 24px;
    margin: 0 0 10px;
    line-height: 1.3;
  }
  .ingress {
    font-family: "Altis-Light","Altis","Open Sans",arial,sans-serif;
    font-weight: 300;
    font-size: 22px;
    line-height: 1.4;
    margin: 16px 0 40px;
  }
  :local(.buttonRow) {
    > * {
      button {
        min-width: 46%;;
      }
    }
  }
}

:local(.buttonRow) {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  > * {
    display: contents;
    color: transparent;

    button {
      margin-right: 0;
    }
  }
}

:local(.textAlignCenter) {
  text-align: center;
  label {
    text-align: left;
  }
}

:local(.inlineInputFields) {
  display: inline-block;
  margin-right: 12px;
}

:local(.radioButtonFlexLabel) {
  label > span:last-child {
    display: block;
    width: 100%;
  }
  :local(.radioButtonLabelContainer){
    display: flex;
    :local(.radioButtonLabelText) {
      flex: 1;
      line-height: 20px;
      :local(.subTitle) {
        display: block;
        font-size: .9em;
      }
    }

    :local(.radioButtonLabelImage) {
      width: 104px;
    }
  }

}
