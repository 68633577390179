body {
    padding: 0;
    background-color: white;
    background-image: linear-gradient(164deg,rgba(137,174,196,0.34),white,rgba(0,117,127,0.19));
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    overflow: hidden;
    font-family: "Open Sans",arial,sans-serif;
  }
  
  a {
    color: #00757F;
  }
  
  :local(.navigationBarText){
    text-align: right;
    margin-right: 12px;
    font-size: 12px;
    @media only screen and (min-width: 40.0625em){
      font-size: 14px;
      margin-right: 21px;
    }
    span {
      display: block;
    }
    a {
      color: #fff;
    }
  }