:local(.container) {
  padding: 30px 40px 0 60px;
  position: absolute;
  height: calc(100% - 73px);
  overflow-y: auto;
  width: 100%;
}

:local(.contentContainer) {
  max-width: 830px;
  margin: 0 auto 40px;
}

:local(.paperTitle) {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 24px;
  line-height: 1.3;
}
